import styled from 'styled-components/macro'
import { components } from 'react-select'
import { ComponentScroll, TrackV } from '@stokr/components-library'

export const SelectControl = styled(components.Control)`
  &&& {
    z-index: 10 !important;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    padding: 15px 0;
    padding-bottom: 5px;
    border-radius: 0;
    box-shadow: none;
    border: 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid #f0f0f0;
    color: #202020;
    background-color: transparent;
    transition: border-color 0.2s;
    cursor: pointer;

    &:hover {
      box-shadow: none;
      border-top-color: transparent;
      border-bottom-color: #0050ca;
    }

    &.active {
      z-index: 12 !important;
    }
  }
`

export const SelectMenu = styled(components.Menu)`
  &&& {
    margin-top: -1px;
    border-radius: 0;
    box-shadow: none;
    border: 0;
    border-top: 1px solid #f0f0f0 !important;
    border-bottom: 1px solid #f0f0f0 !important;
    background-color: #ffffff;
  }
`

export const SelectMenuList = styled(ComponentScroll)`
  ${TrackV} {
    right: 6px;
  }
`

export const SelectOption = styled(components.Option)`
  &&& {
    cursor: pointer;
    padding: 8px 0 !important;
    padding-left: 2px !important;
    font-size: 12px;
    line-height: 18px;
    border: 0;
    background-color: transparent;
    color: #202020;

    ${props =>
      props.isFocused &&
      `
      font-weight: bold;
    `}

    ${props =>
      props.isSelected &&
      `
      font-weight: bold;
    `}

    &:hover {
      background-color: transparent;
      font-weight: bold;
    }
  }
`

export const SelectContainer = styled(components.SelectContainer)`
  z-index: 100;
  ${props =>
    props.selectProps.menuIsOpen &&
    `
    z-index: 150 !important;
  `}
`

export const SelectValueContainer = styled(components.ValueContainer)`
  &&& {
    font-family: 'Open sans';
    padding: 0;
    margin-left: -2px;
    top: -4px;
  }
`

export const SelectIcon = styled.i.attrs({
  className: 'ion ion-ios-arrow-down',
})`
  &&& {
    transition: 0.3s transform;
    transform: rotate(0);

    ${props =>
      props.isMenuOpen &&
      `
      transform: rotate(180deg);
    `}
  }
`

export const SelectWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 24px;

  ${props =>
    props.minWidth &&
    `
    min-width: ${props.minWidth};
  `}

  ${props =>
    props.noMargin &&
    `
    margin-left: 0;
  `}

  &&& ${SelectControl} {
    height: 24px;
    font-weight: normal;
    font-size: 11px;
    line-height: 18px;
    padding: 3px 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }

  &&& ${SelectMenu} {
    left: 0;
    right: 0;
    width: auto;
    margin: -6px -12px;
    margin-top: 0;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    border: 0;
    padding: 6px 12px;
  }

  &&& ${SelectValueContainer} {
    top: 0;
  }
`
