import Cookies from 'js-cookie'

export default () => {
  const inOneHour = 1 / 24

  const cookieOptions = {
    expires: inOneHour,
  }

  if (process.env.NODE_ENV === 'production') {
    cookieOptions.domain = '.stokr.io'
  }

  Cookies.set(
    'STOKR_INVEST_IN_STO_REDIRECT',
    window.location.href,
    cookieOptions,
  )
}
